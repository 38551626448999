<template>
  <div class="w-100">
    <md-card
      v-if="payment_not_found"
      class="w-100 text-center"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-warning">
          warning_amber
        </md-icon>
        <h3 class="title">
          Payment Not Found
        </h3>
        <h5 class="category">
          Contact your agent plase.
        </h5>
      </md-card-header>
    </md-card>

    <md-card
      v-else
      class="w-100 text-center"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-success">
          done_all
        </md-icon>
        <h3 class="title">
          Successful Payment
        </h3>
        <h5 class="category">
          Thank you for your registration. Once your payment has been processed,
          you will receive a confirmation email from us.
        </h5>
      </md-card-header>
    </md-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    payment_not_found: false,
  }),
  mounted() {
    this.saveInvoice();
  },
  methods: {
    saveInvoice() {
      this.axios
        .post(`${process.env.VUE_APP_SERVER}payment/${this.$route.params.token}/save_invoice`, { session_id: this.$route.query.session_id })
        .catch(() => {
          this.payment_not_found = true;
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .md-card-header {
  h3.title {
    color: #3c4858 !important;
    margin-top: 10px;
  }
  h4 {
    font-size: 1.5em;
  }
  h5.category {
    margin-bottom: 0;
  }
  small {
    display: inline-block;
    margin-bottom: 20px;
  }
}
</style>
